import { ChevronRightIcon } from "@heroicons/react/20/solid";
import ExportedImage from "next-image-export-optimizer";
import { motion } from "framer-motion";
import BackgroundPattern from "./BackgroundPattern";
import Link from "next/link";
import LogoText from "@components/shared/LogoText";
import { StarIcon } from "@heroicons/react/24/solid";

export default function Hero() {
  return (
    <div
      className="relative isolate overflow-hidden bg-white dark:bg-gray-900"
      id="vorteile"
    >
      {/* Background pattern */}
      <BackgroundPattern />
      <div className="mx-auto mt-2 max-w-7xl px-6 pt-10 pb-24 sm:mt-10 sm:pb-32 lg:flex lg:px-8 xl:mt-12">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="hidden sm:block">
            <LogoText />
          </div>
          <div className="mt-8 sm:mt-32 lg:mt-16">
            <Link href="/preise" className="inline-flex items-center space-x-6">
              <span className="flex items-center rounded-full bg-orange-500/10 px-3 py-1 text-sm font-semibold leading-6 text-orange-500 ring-1 ring-inset ring-orange-500/20">
                Neuigkeiten
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-5 text-slate-600 dark:text-gray-300 sm:leading-6">
                <span>Lerne BGB AT dauerhaft kostenlos</span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </div>
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-6xl">
            {/* Spare Zeit und lerne einfach */}
            {/* Lerne Jura effizient und einfach */}
            {/* Lerne einfach Jura */}
            Lerne einfach Jura.
          </h1>
          <p className="mt-6 text-lg leading-6 text-slate-600 dark:text-gray-300 sm:leading-8">
            Mehr Verständnis und bessere Noten durch eine moderne
            Prüfungsvorbereitung mit Jurahilfe.de - kompakt, verlinkt und
            interaktiv.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Link
              href="/registrieren"
              id="cta-hero"
              aria-label="CTA in Hero section"
              className="rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400"
            >
              Jetzt kostenlos starten
            </Link>
            <a
              href="#funktionen"
              className="text-sm font-semibold leading-6 text-slate-900 dark:text-white"
            >
              Mehr erfahren <span aria-hidden="true">→</span>
            </a>
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((rating) => (
                <StarIcon
                  key={rating}
                  className="h-5 w-5 flex-shrink-0 text-yellow-400"
                  aria-hidden="true"
                />
              ))}
            </div>
            <p className="ml-2 text-sm font-medium text-slate-600 dark:text-gray-300">
              <span className="font-bold">4.9 von 5 Sternen</span> aus über 60
              Google-Bewertungen
            </p>
          </div>
        </div>

        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 md:-translate-x-20 lg:mt-10 lg:mr-0 lg:max-w-none lg:flex-none">
          <motion.div
            initial={{ opacity: 0, x: 100, scale: 0.8 }}
            whileInView={{ opacity: 1, x: 0, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="max-w-3xl flex-none overflow-hidden rounded-lg sm:max-w-5xl lg:max-w-none"
          >
            <ExportedImage
              className="block dark:hidden"
              src="/images/mockup_laptop_phone_light.png"
              alt="Screenshot der Karteikarte Willenserklärung in Lern-App im Light-Mode"
              width={1024}
              height={1024}
              style={{ objectFit: "cover" }}
              priority
            />
            <ExportedImage
              className="hidden dark:block"
              src="/images/mockup_laptop_phone_dark.png"
              alt="Screenshot der Karteikarte Willenserklärung in Lern-App im Dark-Mode"
              width={1024}
              height={1024}
              style={{ objectFit: "cover" }}
              priority
            />
            {/* <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              width={2432}
              height={1442}
              className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            /> */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
