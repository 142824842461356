import React from "react";
import ExportedImage from "next-image-export-optimizer";
import clsx from "clsx";

import LogoTextWhite from "@assets/logo_fullsize_white.svg";
import LogoTextBlack from "@assets/logo_fullsize_black.svg";

const LogoText = ({
  size = "small",
  className,
}: {
  size?: "small" | "medium" | "large";
  className?: string;
}) => {
  return (
    <>
      <ExportedImage
        className={clsx(
          "hidden dark:block",
          size === "small" && "h-10 max-w-min",
          size === "medium" && "h-12",
          size === "large" && "h-16",
          className
        )}
        src={LogoTextWhite}
        alt="Logo"
      />
      <ExportedImage
        className={clsx(
          "dark:hidden",
          size === "small" && "h-10 max-w-min",
          size === "medium" && "h-12",
          size === "large" && "h-16",
          className
        )}
        src={LogoTextBlack}
        alt="Logo"
      />
    </>
  );
};

export default LogoText;
