import { ArrowPathIcon, UserIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import RevealContainer from "./RevealContainer";
import ExportedImage from "next-image-export-optimizer";

const features: {
  image: string;
  name: string;
  description: string;
  href?: string;
  icon: any;
}[] = [
  {
    name: "Interaktives Lernsystem",
    image: "/images/test_check.gif",
    description:
      "Unser mehrstufiges Lernkonzept ermöglicht es dir, Wissen in kleinen Portionen zu erfassen, mittels eines interaktiven Karteikartensystems zu festigen und im Falltraining mit Multiple-Choice-Aufgaben zu testen.",

    icon: ArrowPathIcon,
  },
  {
    name: "Wissen kompakt und verlinkt",
    image: "/images/link_preview.gif",
    description:
      "Alles relevante Wissen vom ersten Semester bis zu den Examina komprimiert aufbereitet und intuitiv strukturiert für ein schnelles Erfassen des Lernstoffs.",
    icon: DocumentTextIcon,
    href: "/#inhalte",
  },
  {
    name: "Fortschrittsanzeige und Lernstatistiken",
    image: "/images/dashboard_stats.gif",
    description:
      "Eine steigerbare Benotung in den Abschlusstests zu den Lernmodulen und die Visualisierung des Fortschritts in frischem Design motivieren dich, dein Wissen kontinuierlich zu erweitern.",
    icon: UserIcon,
  },
  // {
  //   name: "Einzelunterricht zubuchbar",
  //   description:
  //     "Zusätzlich zu unserer Plattform bieten wir auch personalisierten 1:1-Unterricht an, um dich bei deinem Lernweg zu unterstützen. Unsere erfahrenen Tutoren stehen dir bei Bedarf zur Seite.",

  //   icon: UserIcon,
  // },
];

export default function Features1() {
  return (
    <div className="relative isolate bg-white py-24 dark:bg-gray-900 sm:py-32">
      <svg
        className="absolute inset-0 -z-10 h-full w-full rotate-180 stroke-slate-900/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)] dark:stroke-white/10"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg
          x="50%"
          y={-1}
          className="overflow-visible fill-slate-200/20 dark:fill-gray-800/20"
        >
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-white pt-[7%] dark:from-gray-900" />

      <RevealContainer className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-orange-500">
            Warum Jurahilfe.de?
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-4xl">
            Interaktives Lernen bringt mehr
          </p>
          <p className="mt-6 text-lg leading-6 text-slate-600 dark:text-slate-300 sm:leading-8">
            Lerne aktiv, statt nur passiv zu konsumieren. Unser stufenweises
            Lernkonzept begleitet dich dabei, dir das ganze juristische Wissen
            zu erarbeiten, einzuprägen und an Fallbeispielen zu üben.
          </p>
        </div>
        <RevealContainer className="mx-auto mt-10 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <div className="mb-5 flex lg:justify-center lg:px-2">
                  <ExportedImage
                    src={feature.image}
                    alt={`Feature Vorschau ${feature.name}`}
                    width={300}
                    height={200}
                    className="w-72 rounded-md object-contain shadow-md md:w-96"
                  />
                </div>
                <dt className="mt-4 flex items-center gap-x-3 text-center text-lg font-semibold leading-7 text-slate-900 dark:text-white lg:justify-center">
                  <feature.icon
                    className="h-5 w-5 flex-none text-orange-500"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-6 text-slate-600 dark:text-slate-300 sm:leading-7 ">
                  <p className="flex-auto lg:text-center">
                    {feature.description}
                  </p>
                  {feature.href && (
                    <a
                      href={feature.href}
                      // Workaround to enable beeing clickable
                      onClick={() => true}
                      className="z-10 mx-auto mt-6 cursor-pointer text-sm font-semibold leading-6 text-orange-500"
                    >
                      mehr erfahren <span aria-hidden="true">→</span>
                    </a>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </RevealContainer>
      </RevealContainer>
    </div>
  );
}
