import {
  AcademicCapIcon,
  BuildingLibraryIcon,
  ChartPieIcon,
  DevicePhoneMobileIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { DocumentTextIcon, LightBulbIcon } from "@heroicons/react/24/solid";
import ExportedImage from "next-image-export-optimizer";
import RevealContainer from "./RevealContainer";

const features = [
  {
    name: "Wissen kompakt und verlinkt",
    description:
      "Mit unseren intuitiv erfassbaren und untereinander verlinkten Inhalten sind komplexe rechtliche Zusammenhänge nicht mehr kompliziert.",
    icon: LightBulbIcon,
  },
  {
    name: "Interaktive Tests",
    description:
      "Festige dein Wissen mit interaktiven Wiederholungs-, und Multiple-Choice-Tests.",
    icon: AcademicCapIcon,
  },
  {
    name: "Falltraining",
    description:
      "Gewinne eine neue Perspektive auf theoretisches Wissen durch das Üben an klausurnahen Fällen.",
    icon: BuildingLibraryIcon,
  },
  {
    name: "Persönliches Dashboard",
    description:
      "Behalte den Überblick über deinen Lernfortschritt und mache genau da weiter, wo du aufgehört hast.",

    icon: ChartPieIcon,
  },
  {
    name: "Mobiler Zugriff",
    description:
      "Wechsel in Echtzeit nahtlos vom PC zum Smartphone oder Tablet und wieder zurück mit unserer mobil optimierten Web-App.",
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Persönlicher Einzelunterricht",
    description:
      "1:1 mit einem professionellen Repetitor kannst du individuelle Verständnislücken schließen, Wissen vertiefen und Klausuren bearbeiten.",
    icon: UsersIcon,
  },
];

const stats = [
  { id: 1, name: "Karteikarten", value: "1600+" },
  { id: 2, name: "Multiple-Choice-Fragen", value: "450+" },
  { id: 3, name: "Schemata", value: "190+" },
  { id: 4, name: "Prüfungstipps", value: "260+" },
];

export default function Features2() {
  return (
    <div
      className="relative isolate bg-white py-24 dark:bg-gray-900 sm:py-32"
      id="funktionen"
    >
      <div className="absolute bottom-0 translate-y-14 bg-gradient-to-t from-white pt-[7%] dark:from-gray-900" />
      <RevealContainer className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-orange-500">
            Was bietet Jurahilfe.de?
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-4xl">
            Study smarter, not harder
          </p>
          <p className="mt-6 text-lg leading-6 text-slate-600 dark:text-gray-300 sm:leading-8">
            Spare Zeit und lerne effizient und spielerisch. Didaktisch
            durchdachte Inhalte treffen bei uns auf intelligente Features für
            ein optimales Lernerlebnis.
          </p>
        </div>
      </RevealContainer>
      <RevealContainer className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <ExportedImage
            src="/images/app_home_2_light.png"
            alt="Screenshot der des Dashboard mit Fortschrittsvisualisierung für Jura Rechtsgebiete im Light-Mode"
            className="mb-[-12%] block rounded-xl shadow-2xl ring-1 ring-white/10 dark:hidden"
            width={2432}
            height={1442}
          />
          <ExportedImage
            src="/images/app_home_2_dark.png"
            alt="Screenshot der des Dashboard mit Fortschrittsvisualisierung für Jura Rechtsgebiete im Dark-Mode"
            className="mb-[-12%] hidden rounded-xl shadow-2xl ring-1 ring-white/10 dark:block"
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white  pt-[7%] dark:from-gray-900" />
          </div>
        </div>
      </RevealContainer>
      <RevealContainer className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-slate-600 dark:text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-slate-900 dark:text-white">
                <feature.icon
                  className="absolute top-1 left-1 h-5 w-5 text-orange-500"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
        <div className="mx-auto mt-20 max-w-2xl lg:max-w-none">
          {/* <div className="mx-auto max-w-prose text-center text-base leading-7 text-slate-600 dark:text-gray-300">
            <div className="flex items-center justify-center font-semibold text-slate-900 dark:text-white">
              <HashtagIcon
                className="-mt-0.5 mr-1 h-5 w-5 text-orange-500"
                aria-hidden="true"
              />
              Unsere Inhalte in Zahlen
            </div>{" "}
            <p className="inline">
              Unsere umfassende juristische Bildungsplattform deckt alle
              wesentlichen Themen für deinen Erfolg im Jurastudium ab. Wir
              erweitern unsere Inhalte stetig und freuen uns über dein Feedback.
            </p>
          
          </div> */}
          <RevealContainer className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="flex flex-col bg-slate-800/5  p-8 dark:bg-white/5"
              >
                <dt className="text-sm font-semibold leading-6 text-slate-500 dark:text-slate-300">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-slate-800 dark:text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </RevealContainer>
        </div>
      </RevealContainer>
    </div>
  );
}
