import Link from "next/link";
import Logo from "../shared/Logo";
import InstagramIcon from "../shared/InstagramIcon";

const FooterLink = ({ href, title, newTab = false }) => {
  return (
    <Link
      href={href}
      target={newTab ? "_blank" : "_self"}
      className="text-base leading-6 text-slate-700 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
    >
      {title}
    </Link>
  );
};

export const FooterLogo = () => {
  return (
    <div className="flex h-16 flex-row items-center">
      <Link href="/" aria-label="Home" className="flex items-center">
        <Logo />
        <span className="ml-4 font-extrabold tracking-tight text-slate-900">
          <span className="block text-slate-900 dark:text-slate-100">
            {" "}
            Jurahilfe.de
          </span>
          <span className="block font-normal text-slate-600 dark:text-slate-300">
            Deine interaktive Prüfungsvorbereitung
          </span>
        </span>
      </Link>
    </div>
  );
};

function Footer() {
  return (
    <div className=" flex w-full flex-col justify-between bg-slate-50 p-4 pb-8 pt-14 dark:bg-gray-800">
      <div className="mx-auto flex w-full max-w-7xl flex-col">
        <div className="mb-6 flex items-center justify-between">
          <FooterLogo />
          <Link href="https://www.instagram.com/jurahilfe.de" target={"_blank"}>
            <InstagramIcon className="h-7 w-7 text-slate-700 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200" />
          </Link>
        </div>
        <div className="mb-3 flex w-full flex-wrap items-center justify-center gap-4">
          <FooterLink
            href="https://www.jurahilfe.de/privacy"
            title="Datenschutzerklärung"
            newTab
          />
          <FooterLink
            href="https://www.jurahilfe.de/terms"
            title="Nutzungsbedingungen"
            newTab
          />
          <FooterLink
            href="https://www.jurahilfe.de/impressum"
            title="Impressum"
            newTab
          />
        </div>

        <div className="text-center text-slate-500 dark:text-slate-400">
          &copy; 2023 Jurahilfe.de. Alle Rechte vorbehalten.
        </div>
      </div>
    </div>
  );
}

export default Footer;
