import { YoutubeEmbed } from "@components/shared/YoutubeEmbed";
import RevealContainer from "./RevealContainer";

export default function VideoSection({
  title,
  subtitle,
  text,
  youtubeId,
}: {
  title: string;
  subtitle: string;
  text?: string;
  youtubeId: string;
}) {
  return (
    <div className="relative isolate bg-white py-24 dark:bg-gray-900 sm:py-32">
      <svg
        className="absolute inset-0 -z-10 h-full w-full rotate-180 stroke-slate-900/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)] dark:stroke-white/10"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg
          x="50%"
          y={-1}
          className="overflow-visible fill-slate-200/20 dark:fill-gray-800/20"
        >
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-white pt-[7%] dark:from-gray-900" />

      <RevealContainer className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-orange-500">
            {title}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-slate-900 dark:text-white sm:text-4xl">
            {subtitle}
          </p>
          {text && (
            <p className="mt-6 text-lg leading-6 text-slate-600 dark:text-slate-300 sm:leading-8">
              {text}
            </p>
          )}
        </div>
        <RevealContainer className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-3xl xl:max-w-4xl">
          <div>
            <YoutubeEmbed embedId={youtubeId} />
          </div>
        </RevealContainer>
      </RevealContainer>
    </div>
  );
}
